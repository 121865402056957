import React from 'react';
import type { TemplateData } from '../types';
import { useTranslation } from 'next-i18next';
import ShopItem from './ShopItem';
import ArtistItem from './ArtistItem';
import { css } from '@emotion/react';
import SectionHeader from '../SectionHeader';

type Props = {
  data: TemplateData;
};

const separatorStyle = css`
  margin: 8px 0 24px 0;
  height: 1px;
  background-color: var(--fg-4);
`;

export const ArtistList: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      {data.artistPages && data.artistPages?.length < 3 ? (
        <>
          <SectionHeader>{t('artists')}</SectionHeader>
          {data.artistPages?.map((d) => <ArtistItem {...d} key={d.title} />)}
          <div css={separatorStyle} />
        </>
      ) : null}
    </>
  );
};

export const ShopsList: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionHeader>{t('listen')}</SectionHeader>
      {data.release.services
        ?.filter(({ isVisible, isCustom }) => isVisible || isCustom)
        .map((releaseLink, idx) => (
          <ShopItem key={idx} releaseLink={releaseLink} data={data} />
        ))}
    </>
  );
};
