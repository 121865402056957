import styled from '@emotion/styled';
import { t } from 'i18next';
import { Button, Card } from 'imdui';
import { useSubsPromo } from 'screens/App/PromoBanner';
// eslint-disable-next-line
// @ts-ignore
import bg from './bg.jpg';

const PromoTitle = styled.span`
  color: var(--gray-100, #fff);
  display: block;
  margin-bottom: 16px;
  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Barlow;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 48px */
  letter-spacing: 0.96px;
  text-transform: uppercase;
`;

const PromoSubtitle = styled.span`
  color: var(--gray-100, #fff);
  font-feature-settings: 'ss01' on;

  /* Text/L/Regular */
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
`;

const PromoCardStyled = styled(Card)`
  display: flex;
  grid-column: 1/-1;
  height: 400px;
  padding: 8px;

  border-radius: 40px;
  border: 1px solid var(--accent, #b48b00);
  background: var(--accent-surface-container, #fffbff);

  /* Shadow/3 */
  box-shadow:
    0px 0px 20px 0px rgba(0, 0, 0, 0.05),
    0px 4px 10px -2px rgba(0, 0, 0, 0.1);
`;

const PromoBackground = styled.div`
  width: 100%;
  height: 100%;
  background: url(${bg});
  background-size: contain;
  display: flex;
  border-radius: 32px;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 32px;
  align-self: stretch;
`;

export const PromoCard = () => {
  const [showPromo, onClick] = useSubsPromo();
  if (!showPromo) return null;
  return (
    <PromoCardStyled>
      <PromoBackground>
        <div>
          <PromoTitle>{t('promo-card-title')}</PromoTitle>
          <PromoSubtitle>{t('promo-card-subtitle')}</PromoSubtitle>
        </div>
        <Button
          onClick={onClick}
          style={{ marginTop: 'auto' }}
          size="large"
          primary
          appearance="fill"
          text={t('promo-card-button-text', {
            defaultValue: 'view',
          })}
        />
      </PromoBackground>
    </PromoCardStyled>
  );
};
