import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { LoadingIndicator } from '../../base';
import { SVGIcon } from '../../icon';
import Clickable from '../Clickable';
import { ButtonText } from '..';

const LoadingIndicatorStyled = styled(LoadingIndicator)`
  flex-shrink: 0;
`;

export const ClickableButton = styled(Clickable)<{
  primary: boolean | 'paid' | 'error' | 'black';
  appearance: 'stroke' | 'fill';
  size: 'large' | 'small' | 'medium';
  icon?: boolean;
  position: 'center' | 'left';
}>`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  justify-content: ${({ position, icon, size }) =>
    size === 'small' || !icon ? 'center' : position};
  min-height: ${({ size }) =>
    size === 'large' ? '48px' : size === 'medium' ? '40px' : '32px'};
  border-radius: ${({ size }) =>
    size === 'large' ? '24px' : size === 'medium' ? '20px' : '16px'};
  transition: all 0.1s;
  padding: 0 16px;

  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.1);

  background: ${({ appearance }) =>
    appearance === 'stroke'
      ? 'var(--surface-container-high, #fff)'
      : 'var(--accent, #FEFFFE)'};

  border: 1px solid;

  border-color: ${({ primary, disabled }) => {
    if (disabled) {
      return 'var(--outline-var, #C9C6C4)';
    }
    switch (primary) {
      case 'error':
        return 'var(--state-negative)';
      case 'black':
      case 'paid':
      case true:
        return 'var(--accent, #4FC100)';
      default:
        return 'var(--outline-var, #C9C6C4)';
    }
  }};
  overflow: hidden;

  & > svg path {
    fill: ${({ appearance }) =>
      appearance === 'stroke' ? 'var(--fg-1)' : 'var(--on-accent, #FEFFFE)'};
  }

  ${ButtonText} {
    align-items: center;
    display: flex;
    gap: 4px;
    color: ${({ appearance }) =>
      appearance === 'stroke' ? 'var(--fg-1)' : 'var(--on-accent, #FEFFFE)'};
  }
`;
const RightIcon = styled(SVGIcon)`
  flex-shrink: 0;
  width: auto;
  margin-left: auto;
  padding-left: 12px;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type ButtonProps = {
  text?: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  // icons
  icon?:
    | string
    | React.FC<{ style?: React.CSSProperties; fill: string }>
    | React.VFC<React.SVGProps<SVGSVGElement>>;
  iconLeft?: string | React.FC<{ style?: React.CSSProperties; fill?: string }>;
  iconProps?: {
    style?: React.CSSProperties;
    color?: string;
    size?: number;
  };
  // other props
  type?: string;
  testId?: string;
  autoFocus?: boolean;
  container?: string;
  showLoading?: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | 'large';
  position?: 'left' | 'center';
  children?: React.ReactNode;
  appearance?: 'stroke' | 'fill';
  primary?: boolean | 'paid' | 'error' | 'black';
  className?: string;
  focused?: boolean;
  style?: any;
};

const Button = forwardRef((props: ButtonProps, ref) => {
  const {
    text = null,
    onClick,
    // icons
    icon = null,
    iconLeft = null,
    iconProps = { style: {} },
    // other props
    type,
    testId,
    appearance = 'stroke',
    position = 'center',
    size = 'medium',
    autoFocus = false,
    showLoading = false,
    disabled = false,
    children = null,
    primary = false,
    // style
    className,
    style,
  } = props;
  return (
    <ClickableButton
      style={style}
      ref={ref}
      size={size}
      type={type}
      primary={primary}
      position={position}
      appearance={appearance}
      disabled={disabled}
      className={className}
      testId={testId}
      onClick={onClick}
      autoFocus={autoFocus}
      icon={!!(iconLeft || icon)}
    >
      {iconLeft && typeof iconLeft === 'string' && (
        <SVGIcon
          d={iconLeft}
          {...iconProps}
          style={{
            ...iconProps.style,
          }}
        />
      )}
      {iconLeft &&
        typeof iconLeft === 'function' &&
        iconLeft({
          ...iconProps,
          style: {
            fill: '#333',
            ...iconProps.style,
          },
        })}

      {text !== undefined && (
        <ButtonText size={size} showLoading={showLoading}>
          {text}
        </ButtonText>
      )}

      {icon && typeof icon === 'string' && !showLoading && (
        <RightIcon d={icon} {...iconProps} style={iconProps.style} />
      )}
      {icon &&
        typeof icon === 'function' &&
        !showLoading &&
        icon({
          ...iconProps,
          fill: '#333',
          style: {
            ...iconProps.style,
          },
        })}

      <LoadingWrapper>
        {showLoading && (
          <LoadingIndicatorStyled
            color={appearance === 'fill' ? 'var(--on-accent)' : 'var(--fg-1)'}
          />
        )}
      </LoadingWrapper>

      {children}
    </ClickableButton>
  );
});

Button.displayName = 'Button';

export default Button;
