import styled from '@emotion/styled';
import { accentThemes, DARK_MODE_COLORS } from '@imus/base-ui';
import { BodyS, ContentS } from '@imus/services-ui/src/Text';
import type { SubscriptionId } from 'imddata/types/entities';
import { BodyXS, Button, Card } from 'imdui';
import { useTranslation } from 'react-i18next';

export const mapSubscriptionIdToTheme = (sid: SubscriptionId | 'free') => {
  switch (sid) {
    case 'tier-1':
      return accentThemes.secondary;

    case 'trial-tier-2':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
      return accentThemes.tertiary;

    case 'tier-3':
      return accentThemes.primary;

    case 'trial-monthly':
    case 'tier-2-monthly':
    case 'trial-yearly':
    case 'tier-2-yearly':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
    case 'tier-1-monthly':
    case 'tier-1-yearly':
    case 'free':
    case 'music-analytics':
      return '';
  }
};

const perMonthOrPerYear = (
  sid: SubscriptionId | 'free'
): 'per-month' | 'per-year' | 'per-1-month-trial' | '' => {
  switch (sid) {
    case 'tier-1':
    case 'tier-3':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
    case 'trial-yearly':
    case 'tier-1-yearly':
    case 'tier-2-yearly':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
      return 'per-year';

    case 'trial-tier-2':
      return 'per-1-month-trial';

    case 'music-analytics':
    case 'tier-1-monthly':
    case 'trial-monthly':
    case 'tier-2-monthly':
      return 'per-month';

    case 'free':
      return '';
  }
};

type Feature = { value: string };

type Props = {
  nextPrice?: string;
  actionPrimary?: boolean;
  recommendedText?: string;
  featureChildren?: React.ReactNode;
  subscriptionId: SubscriptionId | 'free';
  price?: React.ReactNode;
  features: Array<Feature>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
  actionText?: string;
  className?: string;
  testId?: string;
  loading?: boolean;
};

const SubscriptionHeaderContainer = styled.div`
  margin-bottom: 24px;
`;

export const SubscriptionName = styled.span<{
  subscriptionId: SubscriptionId | 'free';
}>`
  ${({ subscriptionId }) => mapSubscriptionIdToTheme(subscriptionId)}
  font-family: Barlow, Inter;
  color: ${({ subscriptionId }) =>
    subscriptionId === 'free' ? 'var(--fg-1)' : 'var(--accent)'}; //;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 106.667% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

const Section = styled.div``;
const FeatureSection = styled.div`
  display: grid;
  gap: 2px;
  margin-bottom: auto;
  align-self: flex-start;
`;

const Actions = styled.div`
  display: grid;
  gap: 16px;
  margin-top: auto;
`;

const FeatureBase = ({
  value,
  style,
  className,
  children,
}: Feature & {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) => (
  <div style={style} className={className}>
    <ContentS>{value}</ContentS>
    {children}
  </div>
);

export const SubscriptionCardFeature = styled(FeatureBase)`
  display: flex;
  height: 24px;
  align-items: center;

  justify-content: space-between;
  & > * {
    display: flex;
  }
`;

export const SubscriptionAction = styled(
  (
    props: React.ComponentProps<typeof Button> & {
      subscriptionId: SubscriptionId | 'free';
    }
  ) => (
    <Button
      appearance={props.subscriptionId === 'free' ? 'stroke' : 'fill'}
      primary={props.subscriptionId === 'free' ? false : true}
      {...props}
    ></Button>
  )
)<{
  subscriptionId: SubscriptionId | 'free';
}>`
  ${({ subscriptionId }) => mapSubscriptionIdToTheme(subscriptionId)}
  width: 100%;
`;

const Price = styled.span`
  color: var(--fg-1, rgba(0, 0, 0, 0.95));
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 100% */
`;

const RecommendedChip = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  right: -4px;
  top: -7px;
  color: var(--on-accent);

  font-family: Barlow;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 120% */
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 4px;
  background: var(--accent);
`;

const SubscriptionHeaderBase = ({
  subscriptionId,
  className,
}: {
  className?: string;
  subscriptionId: SubscriptionId | 'free';
}) => {
  const { t } = useTranslation();
  const subtitle =
    subscriptionId === 'free'
      ? t('free-subscription-subtitle')
      : t(subscriptionId + '-subscription-subtitle');
  return (
    <div className={className}>
      <SubscriptionName subscriptionId={subscriptionId}>
        {t(subscriptionId, { context: 'plan' })}
      </SubscriptionName>
      {subtitle && <BodyXS>{subtitle}</BodyXS>}
    </div>
  );
};

export const SubscriptionHeader = styled(SubscriptionHeaderBase)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubscriptionCardBase = ({
  subscriptionId,
  price,
  features,
  testId,
  children,
  style,
  className,
  actionText,
  actionPrimary = true,
  onClick,
  featureChildren,
  recommendedText = '',
  loading,
  nextPrice,
}: Props) => {
  const { t } = useTranslation();
  const cadence = perMonthOrPerYear(subscriptionId);
  const nextCadence = perMonthOrPerYear(
    subscriptionId === 'trial-tier-2' ? 'tier-2-2' : 'free'
  );
  return (
    <Card data-test-id={testId} style={style} className={className}>
      {recommendedText && (
        <RecommendedChip>{t(recommendedText)}</RecommendedChip>
      )}
      <Section>
        <SubscriptionHeaderContainer>
          <SubscriptionHeader subscriptionId={subscriptionId} />
        </SubscriptionHeaderContainer>
        <div>
          <Price>{price}</Price>
          {cadence && cadence.length > 0 && (
            <BodyS>
              &nbsp;
              {t(cadence)}
            </BodyS>
          )}
          {nextPrice && (
            <>
              <br />

              <BodyS>
                {t('next-subscription-price', {
                  context: nextCadence,
                  cadence: t(nextCadence),
                  price: nextPrice,
                  defaultValue: '{{price}} {{cadence}} after',
                })}
              </BodyS>
            </>
          )}
        </div>
      </Section>
      <FeatureSection>
        {features.map((f, idx) => (
          <SubscriptionCardFeature key={idx} {...f} />
        ))}
        {featureChildren}
      </FeatureSection>
      <Actions>
        {children}
        {onClick && (
          <SubscriptionAction
            testId={testId + '-Action-enabled'}
            primary={subscriptionId === 'free' ? false : actionPrimary}
            appearance={
              actionPrimary && subscriptionId !== 'free' ? 'fill' : 'stroke'
            }
            showLoading={loading}
            subscriptionId={subscriptionId}
            text={actionText}
            onClick={onClick}
          />
        )}
      </Actions>
    </Card>
  );
};

export const SubscriptionCard = styled(SubscriptionCardBase)<Props>`
  ${DARK_MODE_COLORS}

  ${({ subscriptionId }) => mapSubscriptionIdToTheme(subscriptionId)}
  overflow: initial;
  position: relative;
  background: var(--background);
  box-shadow: ${({ subscriptionId, recommendedText }) =>
    subscriptionId && recommendedText
      ? 'inset 0 0 0 1px var(--accent)'
      : 'none'};
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px 24px;
  ${SubscriptionName} {
    color: var(--fg-1);
  }
  ${SubscriptionHeaderContainer} ${BodyXS} {
    color: ${({ subscriptionId }) =>
      subscriptionId === 'free' ? 'var(--fg-1)' : 'var(--accent)'}; //;
  }
`;
